import styles from './Accordion.module.scss';

import { useState } from 'react';
import cn from 'classnames';
import DOMPurify from 'isomorphic-dompurify';

import type typeChecks from '@/utilities/types/Checks';
import Typography from '@/components/atoms/Typography';
import Checks from '@/components/molecules/Checks';
import ChevronDown from '@/icons/maggie/ChevronDown';

export interface AccordionProps {
  rows: {
    title: string;
    content: {
      editor?: string;
      checks?: typeChecks[];
    }[];
  }[];
  variant?: 'white' | 'grey';
  rowProps?: (
    row: AccordionProps['rows'][number],
  ) => React.HTMLAttributes<HTMLDivElement>;
  contentProps?: (
    row: AccordionProps['rows'][number],
  ) => React.HTMLAttributes<HTMLDivElement>;
  headingProps?: (
    row: AccordionProps['rows'][number],
  ) => Omit<React.HTMLAttributes<HTMLHeadingElement>, 'style'>;
  textProps?: (
    row: AccordionProps['rows'][number],
  ) => Omit<React.HTMLAttributes<HTMLParagraphElement>, 'style'>;
}

const Accordion = (props: AccordionProps) => {
  const {
    rows,
    variant = props.variant ?? 'white',
    rowProps,
    contentProps,
    headingProps,
    textProps,
  } = props;

  const [activeRows, setActiveRows] = useState(rows.map((row) => false));

  const handleRowClick = (rowIndex: number) => {
    setActiveRows((prev) => {
      const updated = [...prev];
      updated[rowIndex] = !prev[rowIndex];
      return updated;
    });
  };

  return (
    <article className={cn(styles.accordion, styles[variant])}>
      {rows.map((row, _rowIndex) => {
        return (
          <div
            {...(rowProps && rowProps(row))}
            className={cn(
              styles.accordionRow,
              rowProps && rowProps(row).className,
              {
                [styles.active]: activeRows[_rowIndex],
              },
            )}
            key={_rowIndex}
          >
            <button
              className={cn(styles.accordionRowHeader)}
              onClick={(e) => handleRowClick(_rowIndex)}
            >
              <Typography
                {...(headingProps && headingProps(row))}
                xs={3}
                tag="h3"
                className={cn(styles.accordionRowHeaderTitle)}
              >
                {row.title}
              </Typography>
              <ChevronDown />
            </button>
            <div
              {...(contentProps && contentProps(row))}
              className={cn(
                styles.accordionRowContent,
                contentProps && contentProps(row).className,
              )}
            >
              {row.content.map((item, itemIndex) => (
                <div key={itemIndex}>
                  {item.editor && (
                    <Typography
                      {...(textProps && textProps(row))}
                      className={styles.accordionRowContentEditor}
                      xs={3}
                      lg={3}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.editor, {
                            ADD_ATTR: ['target'],
                          }),
                        }}
                      />
                    </Typography>
                  )}
                  {item.checks && (
                    <Checks
                      checks={item.checks}
                      className={styles.accordionRowContentChecks}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </article>
  );
};

export default Accordion;
