import styles from './Faq.module.scss';

import Accordion from '@/components/molecules/Accordion';
import { type AccordionProps } from '@/components/molecules/Accordion/Accordion';

interface FaqProps {
  faqs: {
    editor: string;
    title: string;
  }[];
  style: 'guide' | 'page';
}

const Faq = (props: FaqProps) => {
  const { faqs, style } = props;

  const accordionRows: AccordionProps['rows'] = faqs
    .map(({ editor, title }) => ({
      content: [
        {
          editor,
        },
      ],
      title,
    }))
    .filter((row) => !!row.title);

  if (!accordionRows.length) return <></>;

  return (
    <div
      itemScope
      itemType="https://schema.org/FAQPage"
      className={styles[style]}
    >
      <Accordion
        rows={accordionRows}
        rowProps={(row) => ({
          itemScope: true,
          itemProp: 'mainEntity',
          itemType: 'https://schema.org/Question',
        })}
        contentProps={(row) => ({
          itemScope: true,
          itemProp: 'acceptedAnswer',
          itemType: 'https://schema.org/Answer',
        })}
        headingProps={(row) => ({
          itemProp: 'name',
        })}
        textProps={(row) => ({
          itemProp: 'text',
        })}
      />
    </div>
  );
};

export default Faq;
