import { IconProps } from '@/utilities/types/Icon';

const Check = (props: IconProps) => {
  const { height = 24, width = 24 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6655 6.27566C21.0331 6.64322 21.0331 7.23914 20.6655 7.60669L10.3126 17.9596C9.94502 18.3272 9.3491 18.3272 8.98154 17.9596L4.27566 13.2537C3.90811 12.8862 3.90811 12.2903 4.27566 11.9227C4.64322 11.5552 5.23914 11.5552 5.60669 11.9227L9.64705 15.9631L19.3345 6.27566C19.7021 5.90811 20.298 5.90811 20.6655 6.27566Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Check;
