import styles from './Checks.module.scss';

import Check from '@/icons/maggie/Check';
import cn from 'classnames';
import DOMPurify from 'isomorphic-dompurify';
import typeChecks from '@/utilities/types/Checks';

interface ChecksProps {
  checks: typeChecks[];
  className?: string;
}

const Checks = (props: ChecksProps) => {
  const { checks, className } = props;

  return (
    <div className={cn(className, styles.root)}>
      <ul>
        {checks.map((check, index) => (
          <li key={index}>
            <i className={styles[check.color]}>
              <Check height={16} width={16} />
            </i>
            {check.title && (
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(check.title),
                }}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Checks;
